<mat-toolbar class="conference-list-toolbar">
	<span>FISH</span>
	<span>Junge Sprachwissenschaft e.&thinsp;V.</span>
</mat-toolbar>

<mat-toolbar class="small-font">
	<span class="fill-space"></span>

	<button mat-button
	        *ngIf="!isLoggedIn"
		(click)="login()">
		Admin
	</button>

	<button mat-button
	        *ngIf="isLoggedIn"
		(click)="logout()">
		Log out
	</button>
</mat-toolbar>

<mat-nav-list>
	<mat-list-item *ngFor="let conf of conferences" [routerLink]="['/', conf.key]">
		<a mat-line>{{conf.key}}</a>

		<button mat-icon-button
		        *ngIf="isLoggedIn && conf.admin"
		        [routerLink]="['/editor', conf.key]">
			<span class="material-icons">edit</span>
		</button>
	</mat-list-item>
</mat-nav-list>
