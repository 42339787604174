<form (submit)="confirm($event.target)">
	<mat-toolbar class="top">
		<img *ngIf="conference"
		     [src]="conference?.avatar"
		     alt="..."
		     style="width: 40px;">

		<div class="flex">
			<span> {{conference?.name}} </span>

			<span>{{labels.headline}}</span>
		</div>
	</mat-toolbar>

	<p *ngIf="succesfullySaved">
		{{labels.registrationSuccessful}}
	</p>

	<confreg-questionnaire [conferenceKey]="conference?.key"
	                       [items]="conference?.registration"
	                       [readonly]="readonly"
	                       (onChange)="inputModel = $event"
	                       *ngIf="!succesfullySaved"></confreg-questionnaire>

	<mat-toolbar class="bottom"
	            *ngIf="!succesfullySaved">
	    <span>
	      <button mat-raised-button type="submit">
	        {{labels.submit}}
	      </button>
	    </span>
	</mat-toolbar>
</form>
