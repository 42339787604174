<div *ngFor="let item of items; let i = index" class="registrationItem"
     #itemContainer>
	<mat-card [ngSwitch]="item.type">
		<mat-card-actions *ngIf="editable">
			<button mat-icon-button
			        (click)="moveUp(itemContainer, item.key)"
			        [disabled]="i === 0">
				<span class="material-icons">arrow_upward</span>
			</button>

			<button mat-icon-button
			        (click)="moveDown(itemContainer, item.key)"
			        [disabled]="i + 1 === items.length">
				<span class="material-icons">arrow_downward</span>
			</button>

			<mat-slide-toggle (change)="toggleEditing(item.key)">
				Edit
			</mat-slide-toggle>

			<span class="fill-space"></span>

			<form (submit)="changeKey(item, newKey.value)">
				<mat-form-field class="non-full-width">
					<input matInput floatingPlaceholder
					       placeholder="Key"
					       [value]="item.key"
					       #newKey>
				</mat-form-field>

				<button mat-button>
					Change key
				</button>
			</form>

			<button mat-button
			        (click)="removeItem(item.key)"
			        color="warn">
				Delete
			</button>
		</mat-card-actions>

		<div *ngSwitchCase="'Caption'">
			<mat-card-title *ngIf="item.title && !currentlyEditing[item.key]">
				{{item.title}}
			</mat-card-title>

			<mat-card-content *ngIf="item.text && !currentlyEditing[item.key]"
			                 class="long-text"
			                 [innerHTML]="item.text">
			</mat-card-content>

			<mat-card-content *ngIf="currentlyEditing[item.key]">
				<p>
					Key: {{item.key}}
				</p>
				<p>
					<mat-form-field>
						<input matInput floatingPlaceholder
						       placeholder="Title"
						       [(ngModel)]="item.title">
					</mat-form-field>
				</p>
				<p>
					<mat-form-field>
						<textarea matInput floatingPlaceholder
						          placeholder="Text"
						          [(ngModel)]="item.text">
						</textarea>
					</mat-form-field>
				</p>
			</mat-card-content>
		</div>

		<div *ngSwitchCase="'Link'">
			<p *ngIf="!currentlyEditing[item.key]">
				<a target="_blank" [href]="item.href">{{item.label || item.href}}</a>
			</p>

			<div *ngIf="currentlyEditing[item.key]">
				<p>
					Key: {{item.key}}
				</p>
				<mat-form-field>
					<input matInput floatingPlaceHolder
					       placeholder="Label"
					       [(ngModel)]="item.label">
				</mat-form-field>

				<mat-form-field>
					<input matInput floatingPlaceholder
					       placeholder="Address"
					       [(ngModel)]="item.href">
				</mat-form-field>
			</div>
		</div>

		<div *ngSwitchCase="'TextInput'">
			<mat-card-content *ngIf="!currentlyEditing[item.key]">
				<mat-form-field>
					<textarea *ngIf="item.longText"
					          matInput floatingPlaceholder
					          [placeholder]="item.caption"
					          [required]="!item.optional"
					          [value]="inputModel[item.key]"
					          (change)="changeInputModel(item.key, $event.target.value)"
					          [disabled]="readonly"
					          [rows]="item.rows"></textarea>
					<input *ngIf="!item.longText"
					       matInput floatingPlaceholder
					       [placeholder]="item.caption"
					       [required]="!item.optional"
					       [value]="inputModel[item.key]"
					       (change)="changeInputModel(item.key, $event.target.value)"
					       [disabled]="readonly">
					<mat-hint>{{item.hint}}</mat-hint>
				</mat-form-field>
			</mat-card-content>

			<mat-card-content *ngIf="currentlyEditing[item.key]">
				<p>
					Key: {{item.key}}
				</p>

				<mat-form-field>
					<input matInput floatingPlaceholder
					       [required]="true"
					       placeholder="Caption"
					       [(ngModel)]="item.caption">
				</mat-form-field>

				<mat-form-field>
					<input matInput floatingPlaceholder
					       placeholder="Hint"
					       [(ngModel)]="item.hint">
				</mat-form-field>

				<mat-checkbox [(ngModel)]="item.optional">Optional</mat-checkbox>

				<br>

				<mat-checkbox [(ngModel)]="item.longText">Long text</mat-checkbox>
				<mat-form-field class="non-full-width">
					<input matInput floatingPlaceholder
					       [required]="true"
					       placeholder="Rows"
					       [(ngModel)]="item.rows"
					       [disabled]="!item.longText"
					       type="number">
					<mat-hint>This only affects how large the user’s text area appears (which they can change).</mat-hint>
				</mat-form-field>
			</mat-card-content>
		</div>

		<div *ngSwitchCase="'SingleChoice'">
			<mat-card-content *ngIf="!currentlyEditing[item.key]">
				<p class="long-text" [innerHTML]="item.caption"></p>

				<mat-radio-group [value]="inputModel[item.key]"
				                (change)="changeInputModel(item.key, $event.value)">
					<mat-list>
						<mat-list-item *ngFor="let choice of item.choices">
							<mat-radio-button [value]="choice.key" [name]="item.key" [disabled]="readonly">
								{{choice.caption}}
							</mat-radio-button>
						</mat-list-item>
					</mat-list>
				</mat-radio-group>
			</mat-card-content>

			<mat-card-content *ngIf="currentlyEditing[item.key]">
				<p>
					Key: {{item.key}}
				</p>

				<mat-form-field>
					<textarea matInput floatingPlaceholder
					          [required]="true"
					          placeholder="Caption"
					          [(ngModel)]="item.caption">
					</textarea>
				</mat-form-field>

				<h2>Options:</h2>

				<p>
					<button mat-icon-button
					        (click)="addChoice(item)">
						<span class="material-icons">add_circle</span>
					</button>
				</p>

				<mat-list>
					<mat-list-item *ngFor="let choice of item.choices">
						<button mat-icon-button
						        (click)="removeChoice(item, choice.key)">
							<span class="material-icons">remove_circle</span>
						</button>

						<mat-form-field>
							<input matInput floatingPlaceholder
							       [required]="true"
							       placeholder="Key"
							       [(ngModel)]="choice.key">
						</mat-form-field>

						<mat-form-field>
							<input matInput floatingPlaceholder
							       [required]="true"
							       placeholder="Caption"
							       [(ngModel)]="choice.caption">
						</mat-form-field>
					</mat-list-item>
				</mat-list>
			</mat-card-content>
		</div>

		<div *ngSwitchCase="'MultipleChoice'">
			<mat-card-content *ngIf="!currentlyEditing[item.key]">
				<p class="long-text" [innerHTML]="item.caption"></p>

				<mat-list>
					<mat-list-item *ngFor="let choice of item.choices">
						<mat-checkbox [value]="inputModel[choice.key]"
						             (change)="changeInputModel(choice.key, $event.checked)"
						             [disabled]="readonly">
							{{choice.caption}}
						</mat-checkbox>
					</mat-list-item>
				</mat-list>
			</mat-card-content>

			<mat-card-content *ngIf="currentlyEditing[item.key]">
				<p>
					Key: {{item.key}}
				</p>

				<mat-form-field>
					<textarea matInput floatingPlaceholder
					          [required]="true"
					          placeholder="Caption"
					          [(ngModel)]="item.caption">
					</textarea>
				</mat-form-field>

				<h2>Options:</h2>

				<p>
					<button mat-icon-button
					        (click)="addChoice(item)">
						<span class="material-icons">add_circle</span>
					</button>
				</p>

				<mat-list>
					<mat-list-item *ngFor="let choice of item.choices">
						<button mat-icon-button
						        (click)="removeChoice(item, choice.key)">
							<span class="material-icons">remove_circle</span>
						</button>

						<mat-form-field>
							<input matInput floatingPlaceholder
							       [required]="true"
							       placeholder="Key"
							       [(ngModel)]="choice.key">
						</mat-form-field>

						<mat-form-field>
							<input matInput floatingPlaceholder
							       [required]="true"
							       placeholder="Caption"
							       [(ngModel)]="choice.caption">
						</mat-form-field>
					</mat-list-item>
				</mat-list>
			</mat-card-content>
		</div>
	</mat-card>
</div>
