<mat-toolbar>
	<span *ngIf="!previewMode" class="toolbar-heading">
	        Editing questionnaire: {{conference?.key}}
	</span>

	<img *ngIf="previewMode" [src]="conference?.avatar">

	<span *ngIf="previewMode">
		{{conference?.name}}
	</span>

	<span class="fill-space"></span>

	<div class="toolbar-actions">
		<mat-slide-toggle (change)="previewMode = $event.checked">
			Preview
		</mat-slide-toggle>
	</div>
</mat-toolbar>

<mat-toolbar *ngIf="!previewMode">
	<span class="toolbar-heading">Add items/questions</span>

	<span class="fill-space"></span>

	<div class="toolbar-actions">
		<button mat-button (click)="questionnaire.addItem('Caption')">Caption</button>
		<button mat-button (click)="questionnaire.addItem('Link')">Link</button>
		<button mat-button (click)="questionnaire.addItem('TextInput')">Text input</button>
		<button mat-button (click)="questionnaire.addItem('SingleChoice')">Single choice</button>
		<button mat-button (click)="questionnaire.addItem('MultipleChoice')">Multiple choice</button>
	</div>
</mat-toolbar>

<confreg-questionnaire #questionnaire
                       [conferenceKey]="conference.key"
                       [editable]="!previewMode"
                       [items]="conference.registration"
                       (onEdit)="edited($event)">
</confreg-questionnaire>
